<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="relative overflow-hidden ib-signup-vue">
    <Navigation
      :show-language-switcher="true"
      :show-login="true"
      :show-user-menu="false"
    ></Navigation>
    <main class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 h-90v">
      <div class="text-center">
        <p
          class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
        >
          {{ $t("select-the-type-of-ib-account") }}
        </p>
        <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <div class="rounded-md shadow">
            <router-link
              class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 md:py-4 md:text-lg md:px-10"
              to="/ib/signup/individual"
            >
              {{ $t("Individual") }}
            </router-link>
          </div>
          <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
            <router-link
              class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-700 group md:py-4 md:text-lg md:px-10"
              to="/ib/signup/company"
            >
              <span class="group-hover:text-white">{{ $t("Company") }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>
<script>
import Cookie from "js-cookie";
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";

export default {
  name: "SelectTypeOfAccount",
  components: {
    Navigation,
    Footer,
  },
  created() {
    if (this.$route.query.t && !Cookie.get("x-tracking-id")) {
      Cookie.set("x-tracking-id", this.$route.query.t);
    }
    if (Cookie.get("x-tracking-id")) {
      this.$store.dispatch(
        "ib_tracking_link_visited",
        Cookie.get("x-tracking-id")
      );
    }
    this.$store.dispatch("countries");
  },
};
</script>